//core blocks
import Cover from "@litonarefin/components/CoreBlocks/Cover";
import Heading from "@litonarefin/components/CoreBlocks/Heading";
import Paragraph from "@litonarefin/components/CoreBlocks/Paragraph";
import Columns from "@litonarefin/components/CoreBlocks/Columns";
import Column from "@litonarefin/components/CoreBlocks/Column";
import Code from "@litonarefin/components/CoreBlocks/Code";
import List from "@litonarefin/components/CoreBlocks/List";
import ListItem from "@litonarefin/components/CoreBlocks/ListItem";
import Html from "@litonarefin/components/CoreBlocks/Html";
import Image from "@litonarefin/components/CoreBlocks/Image";
import Buttons from "@litonarefin/components/CoreBlocks/Buttons";
import Button from "@litonarefin/components/CoreBlocks/Button";
import Video from "@litonarefin/components/CoreBlocks/Video";
import Separator from "@litonarefin/components/CoreBlocks/Separator";
import Youtube from "@litonarefin/components/CoreBlocks/Youtube";
import Spacer from "@litonarefin/components/CoreBlocks/Spacer";
//master blocks
import CounterUp from "@litonarefin/components/MbBlocks/CounterUp";
import MbButtons from "@litonarefin/components/MbBlocks/Buttons";
import MbButton from "@litonarefin/components/MbBlocks/Button";
// import TestimonialSlider from "@litonarefin/components/MbBlocks/TestimonialSlider";
// //jlt blocks
import Product from "@litonarefin/components/JltBlocks/Product";
import Support from "@litonarefin/components/JltBlocks/Support";
import BgBanner from "@litonarefin/components/JltBlocks/BgBanner";
import Blockquote from "@litonarefin/components/JltBlocks/Blockquote";
import Rating from "@litonarefin/components/JltBlocks/Rating";
import Container from "@litonarefin/components/JltBlocks/Container";
import Pricing from "@litonarefin/components/JltBlocks/Pricing";
import Switcher from "@litonarefin/components/JltBlocks/Switcher";
import SwitcherChild from "@litonarefin/components/JltBlocks/SwitcherChild";
import IconText from "@litonarefin/components/JltBlocks/IconText";
import PriceCard from "@litonarefin/components/JltBlocks/PriceCard";
import DocsCard from "@litonarefin/components/JltBlocks/DocsCard";
import Faq from "@litonarefin/components/JltBlocks/Faq";
import FreemiusButton from "@litonarefin/components/JltBlocks/FreemiusButton";
import WooCommerceButton from "@litonarefin/components/JltBlocks/WooCommerceButton";
import PaddleCheckout from "@litonarefin/components/JltBlocks/PaddleCheckout";
import SubscribeButton from "@litonarefin/components/JltBlocks/SubscribeButton";
import DocsSearchBlock from "@litonarefin/components/JltBlocks/DocsSearchBlock";
import WooCommearceReviews from "@litonarefin/components/JltBlocks/WooCommearceReviews";
import SubscribeForm from "@litonarefin/components/JltBlocks/SubscribeForm";
import FeaturedPlugin from "@litonarefin/components/JltBlocks/FeaturedPlugin";
import InfoBox from "@litonarefin/components/JltBlocks/InfoBox";
import FilterTab from "@litonarefin/components/JltBlocks/FilterTab";
import AdvancedPricing from "@litonarefin/components/JltBlocks/AdvancedPricing";
import JltButtons from "@litonarefin/components/JltBlocks/Buttons";
import JltButton from "@litonarefin/components/JltBlocks/Button";
import JltContactForm from "@litonarefin/components/JltBlocks/JltContactForm";
import JltAppsumoCodeRedemption from "@litonarefin/components/JltBlocks/JltAppsumoCodeRedemption";
import JltFreemiusAffiliateForm from "@litonarefin/components/JltBlocks/JltFreemiusAffiliateForm";
import JltFluentForm from "@litonarefin/components/JltBlocks/FluentForm";
import FluentFormClient from "@litonarefin/components/JltBlocks/FluentForm/index-client";
import ComparisonTable from "@litonarefin/components/JltBlocks/ComparisonTable";
import GlossaryPosts from "@litonarefin/components/JltBlocks/GlossaryPosts";
import { JltContainerScroll } from "@litonarefin/components/JltBlocks/JltContainerScroll";
import NewPricing from "@litonarefin/components/JltBlocks/NewPricing/NewPricing";
import LiveDemoForm from "@litonarefin/components/JltBlocks/LiveDemoForm";

export const BlockRenderer = ({ blocks }) => {
  return blocks?.map((block) => {
    switch (block?.name) {
      case "core/columns": {
        return (
          <Columns
            key={block?.id}
            attributes={block?.attributes}
            colNumbers={block.innerBlocks?.length}
          >
            <BlockRenderer blocks={block.innerBlocks} />
          </Columns>
        );
      }
      case "core/column": {
        return (
          <Column key={block?.id} attributes={block?.attributes}>
            <BlockRenderer blocks={block.innerBlocks} />
          </Column>
        );
      }
      case "core/video": {
        return <Video key={block?.id} attributes={block?.attributes} />;
      }
      case "core/embed": {
        return <Youtube key={block?.id} attributes={block?.attributes} />;
      }
      case "core/buttons": {
        return (
          <Buttons key={block.id} attributes={block?.attributes}>
            <BlockRenderer blocks={block.innerBlocks} />
          </Buttons>
        );
      }
      case "core/button": {
        return <Button key={block.id} attributes={block?.attributes} />;
      }
      case "core/html": {
        return (
          <Html
            key={block.id}
            attributes={block?.attributes}
            originalContent={block?.originalContent}
          />
        );
      }
      case "core/list-item": {
        return <ListItem key={block.id} attributes={block?.attributes} />;
      }
      case "core/list": {
        return (
          <List
            key={block.id}
            attributes={block?.attributes}
            originalContent={block?.originalContent}
          >
            <BlockRenderer blocks={block.innerBlocks} />
          </List>
        );
      }
      case "core/code": {
        return <Code key={block.id} attributes={block?.attributes} />;
      }
      case "core/cover": {
        return (
          <Cover key={block.id} image={block.attributes.url}>
            <BlockRenderer blocks={block.innerBlocks} />
          </Cover>
        );
      }
      case "core/heading": {
        return <Heading key={block.id} attributes={block?.attributes} />;
      }
      case "core/paragraph": {
        return <Paragraph key={block?.id} attributes={block?.attributes} />;
      }
      // case "core/group":
      // case "core/block": {
      //     return <BlockRenderer key={block?.id} blocks={block.innerBlocks} />;
      // }
      case "core/image": {
        return <Image key={block?.id} attributes={block?.attributes} />;
      }
      case "core/separator": {
        return <Separator key={block?.id} attributes={block?.attributes} />;
      }
      case "core/spacer": {
        return <Spacer key={block?.id} attributes={block?.attributes} />;
      }

      // Jlt core components
      case "jlt-core/advanced-pricing": {
        return <AdvancedPricing key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/filter-tab": {
        return <FilterTab key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/info-box": {
        return <InfoBox key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/featured-plugin": {
        return <FeaturedPlugin key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/subscribe-form": {
        return <SubscribeForm key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/product": {
        return <Product key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/support": {
        return <Support key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/bg-banner": {
        return (
          <BgBanner key={block?.id} attributes={block?.attributes}>
            <BlockRenderer blocks={block?.innerBlocks} />
          </BgBanner>
        );
      }
      case "jlt-core/blockquote": {
        return <Blockquote key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/rating": {
        return <Rating key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/container": {
        return (
          <Container key={block?.id} attributes={block?.attributes}>
            <BlockRenderer blocks={block?.innerBlocks} />
          </Container>
        );
      }
      case "jlt-core/pricing": {
        return (
          <Pricing key={block?.id} attributes={block?.attributes}>
            <BlockRenderer blocks={block?.innerBlocks} />
          </Pricing>
        );
      }
      case "jlt-core/switcher": {
        return (
          <Switcher key={block?.id} attributes={block?.attributes} blocks={block?.innerBlocks} />
        );
      }
      case "jlt-core/switcher-child": {
        return (
          <SwitcherChild key={block?.id}>
            <BlockRenderer blocks={block?.innerBlocks} />
          </SwitcherChild>
        );
      }
      case "jlt-core/icon-text": {
        return <IconText key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/price-card": {
        return <PriceCard key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/docs-card": {
        return <DocsCard key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/faq": {
        return <Faq key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/freemius-button": {
        return <FreemiusButton key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/woo-commerce-button": {
        return <WooCommerceButton key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/paddle-checkout": {
        return <PaddleCheckout key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/subscribe-button": {
        return <SubscribeButton key={block?.id} attributes={block?.attributes} />;
      }
      // case "jlt-core/blog-category": {
      //     return <JltBlogCategory key={block?.id} attributes={block?.attributes} />;
      // }
      case "jlt-core/docs-search": {
        return <DocsSearchBlock key={block?.id} />;
      }
      case "jlt-core/woo-commerce-review": {
        return <WooCommearceReviews key={block.id} attributes={block?.attributes} />;
      }
      case "jlt-core/counter-up": {
        return <CounterUp key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/buttons": {
        return (
          <JltButtons key={block.id} attributes={block?.attributes}>
            <BlockRenderer blocks={block.innerBlocks} />
          </JltButtons>
        );
      }
      case "jlt-core/button": {
        return <JltButton key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/contact-form": {
        return <JltContactForm key={block?.id} />;
      }
      case "jlt-core/appsumo-code-redemption": {
        return <JltAppsumoCodeRedemption key={block?.id} />;
      }
      case "jlt-core/freemius-affiliate-form": {
        return <JltFreemiusAffiliateForm key={block?.id} />;
      }
      case "jlt-core/fluent-form": {
        if (block?.attributes?.isLagyLoad) {
          return <FluentFormClient key={block?.id} attributes={block?.attributes} />;
        } else {
          return <JltFluentForm key={block?.id} attributes={block?.attributes} />;
        }
      }
      case "jlt-core/comparison-table": {
        return <ComparisonTable key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/glossary-posts": {
        return <GlossaryPosts key={block?.id} />;
      }
      case "jlt-core/container-scroll": {
        return <JltContainerScroll key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/new-pricing": {
        return <NewPricing key={block?.id} attributes={block?.attributes} />;
      }
      case "jlt-core/live-demo-form": {
        return <LiveDemoForm key={block?.id} attributes={block?.attributes} />;
      }

      // //master blocks
      case "master-blocks/buttons": {
        return (
          <MbButtons key={block?.id} attributes={block?.attributes}>
            <BlockRenderer blocks={block.innerBlocks} />
          </MbButtons>
        );
      }
      case "master-blocks/button-child": {
        return <MbButton key={block?.id} attributes={block?.attributes} />;
      }

      // case "master-blocks/testimonial-slider": {
      //     return <TestimonialSlider key={block?.id} attributes={block?.attributes} />;
      // }
      default:
        return null;
    }
  });
};
