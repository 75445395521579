"use client";

import { getBorderCSS } from "@litonarefin/utils/getBorderCSS";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { LAPTOP, MOBILE, TABLET } from "@litonarefin/utils/breakpoint";
import getTypographyCSS from "@litonarefin/utils/getTypographyCSS";
import Loader from "../Loader";

const LiveDemoForm = ({ attributes }) => {
  const {
    margin,
    padding = {
      desktop: {
        top: "32px",
        right: "32px",
        bottom: "32px",
        left: "32px",
      },
      laptop: {},
      tablet: {},
      mobile: {},
    },
    bgColor,
    border = { color: "#cfd2d6", style: "solid", width: "1px" },
    borderRadius,
    labelTypography = {
      desktop: {
        fontSize: "18",
        fontWeight: "500",
      },
      laptop: {
        fontSize: "",
        fontWeight: "",
      },
      tablet: {
        fontSize: "",
        fontWeight: "",
      },
      mobile: {
        fontSize: "",
        fontWeight: "",
      },
    },
    labelColor = "#2f2f2f",
    inputTypography = {
      desktop: {
        fontSize: "16",
        fontWeight: "500",
      },
      laptop: {
        fontSize: "",
        fontWeight: "",
      },
      tablet: {
        fontSize: "",
        fontWeight: "",
      },
      mobile: {
        fontSize: "",
        fontWeight: "",
      },
    },
    inputColor,
    inputBorder = { color: "#cfd2d6", style: "solid", width: "1px" },
    inputBorderRadius = "2",
    inputPadding = {
      desktop: { top: "8px", bottom: "8px", right: "16px", left: "16px" },
      laptop: {},
      tablet: {},
      mobile: {},
    },
    //Button
    btnText,
    btnColor = "#fff",
    btnBgColor = "#126dff",
    btnBorder,
    btnBorderRadius = "4",
    btnPadding = {
      bottom: "12px",
      left: "16px",
      right: "16px",
      top: "12px",
    },
    btnWidth = "100%",
    productSlug,
  } = attributes;

  const { typography: labelTypo } = getTypographyCSS(labelTypography);
  const { typography: inputTypo } = getTypographyCSS(inputTypography);

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.currentTarget);

    const data = Object.fromEntries(formData.entries());

    const response = await fetch(`/api/live-demo`, {
      method: "POST",
      body: JSON.stringify({ ...data, product: productSlug }),
    });

    const result = await response.json();

    setIsLoading(false);

    if (result.success) {
      setMessage("success");
    } else {
      setMessage("error");
    }
  }

  if (message === "success") {
    return (
      <div className="jlt-bg-[#dff0d8] jlt-text-[#215022] jlt-p-4 jlt-rounded-md jlt-border jlt-border-[#8ec362]">
        Your WP Spotlight Live Demo site is ready! Please check inbox for Admin Access.
      </div>
    );
  } else if (message === "error") {
    return (
      <div className="jlt-bg-[#fed2d1] jlt-text-[#933533] jlt-p-4 jlt-rounded-md jlt-border jlt-border-[#933533]">
        This user account cannot register any more demo sites.
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className="jlt_live-demo-form jlt-flex jlt-flex-col jlt-gap-5">
      <div className="jlt_demo-name jlt-grid jlt-grid-cols-2 jlt-items-center jlt-gap-4">
        <div className="jlt_demo-input-wrapper">
          <label>First Name</label>
          <input placeholder="First Name" name="epd_first_name" className="jlt-w-full jlt-mt-2" />
        </div>

        <div className="jlt_demo-input-wrapper">
          <label>Last Name</label>
          <input placeholder="Last Name" name="epd_last_name" className="jlt-w-full jlt-mt-2" />
        </div>
      </div>

      <div className="jlt_demo-input-wrapper">
        <label>Email Address</label>
        <input placeholder="Email Address" name="epd_email" className="jlt-w-full jlt-mt-2" />
      </div>

      <button
        type="submit"
        className="jlt_live-demo-button jlt-mt-4 jlt-flex jlt-items-center jlt-justify-center jlt-gap-2 jlt-cursor-pointer"
      >
        {!!isLoading ? (
          <Loader
            type="button-loader"
            styles={{
              width: "24px",
              height: "24px",
            }}
          />
        ) : null}
        {btnText || "Live Demo"}
      </button>

      {/* Styles */}
      <style jsx>{`
        .jlt_live-demo-form {
          ${!isEmpty(margin?.desktop) ? getDimensionCSS(margin?.desktop, "margin") : ""}
          ${!isEmpty(padding?.desktop) ? getDimensionCSS(padding?.desktop, "padding") : ""}
          ${bgColor ? `background: ${bgColor};` : ""}
          ${getBorderCSS(border) ? getBorderCSS(border) : ""}
          ${borderRadius ? `border-radius: ${borderRadius}px;` : ""}
        }

        .jlt_demo-input-wrapper {
          label {
            ${labelTypo}
            ${labelColor ? `color: ${labelColor};` : ""}
          }
          input {
            ${inputTypo}
            ${inputColor ? `color: ${inputColor};` : ""}
            ${!isEmpty(inputPadding?.desktop)
              ? getDimensionCSS(inputPadding?.desktop, "padding")
              : ""}
            ${getBorderCSS(inputBorder) ? getBorderCSS(inputBorder) : ""}
            ${inputBorderRadius ? `border-radius: ${inputBorderRadius}px;` : ""}
          }
        }

        .jlt_live-demo-button {
          ${getMinifyCSS(`
                ${btnBgColor ? `background: ${btnBgColor};` : ""}
                ${btnColor ? `color: ${btnColor};` : ""}
                ${btnBorderRadius ? `border-radius: ${btnBorderRadius}px;` : ""}
                ${btnWidth ? `width: ${btnWidth};` : ""}
                ${getBorderCSS(btnBorder) ? getBorderCSS(btnBorder) : ""}
                ${
                  getDimensionCSS(btnPadding, "padding")
                    ? getDimensionCSS(btnPadding, "padding")
                    : ""
                }
            `)}
        }

        //laptop device
        @media only screen and (max-width: ${LAPTOP}px) {
          .jlt_live-demo-form {
            ${!isEmpty(margin?.laptop) ? getDimensionCSS(margin?.laptop, "margin") : ""}
            ${!isEmpty(padding?.laptop) ? getDimensionCSS(padding?.laptop, "padding") : ""}
          }

          .jlt_demo-input-wrapper {
            input {
              ${!isEmpty(inputPadding?.laptop)
                ? getDimensionCSS(inputPadding?.laptop, "padding")
                : ""}
            }
          }
        }

        //tablet device
        @media only screen and (max-width: ${TABLET}px) {
          .jlt_live-demo-form {
            ${!isEmpty(margin?.tablet) ? getDimensionCSS(margin?.tablet, "margin") : ""}
            ${!isEmpty(padding?.tablet) ? getDimensionCSS(padding?.tablet, "padding") : ""}
          }

          .jlt_demo-input-wrapper {
            input {
              ${!isEmpty(inputPadding?.tablet)
                ? getDimensionCSS(inputPadding?.tablet, "padding")
                : ""}
            }
          }
        }

        // mobile device
        @media only screen and (max-width: ${MOBILE}px) {
          .jlt_live-demo-form {
            ${!isEmpty(margin?.mobile) ? getDimensionCSS(margin?.mobile, "margin") : ""}
            ${!isEmpty(padding?.mobile) ? getDimensionCSS(padding?.mobile, "padding") : ""}
          }

          .jlt_demo-input-wrapper {
            input {
              ${!isEmpty(inputPadding?.mobile)
                ? getDimensionCSS(inputPadding?.mobile, "padding")
                : ""}
            }
          }
        }
      `}</style>
    </form>
  );
};

export default LiveDemoForm;
